import React, { useEffect, useState } from "react";
import cx from "classnames";
import useSWR from "swr";
import { RevealOnMount } from "src/components/reveal-on-mount";
import { SEO } from "src/components/SEO";
import { Collection, CollectionInterface } from "src/components/collection";
import { client } from "src/context/siteContext";
import { useLocation } from "@reach/router";

const fetcher = () => client.product.fetchAll(250);

interface ProductLandingPageProps {
  pageContext: {
    main: {
      title: string;
      slug: {
        current: string;
      };
      categories: [
        {
          _id: string;
          slug: {
            current: string;
          };
          collections: [CollectionInterface];
        }
      ];
    };
    meta: {};
  };
  location: any;
  path: string;
}

const ProductLandingPage = ({
  location,
  path,
  pageContext,
}: ProductLandingPageProps) => {
  const { main, meta } = pageContext;
  const { categories, slug } = main;

  const isCoursePLP =
    slug.current === "mindfulness" || slug.current === "mindfulness-courses";

  // fetch live product data
  const { data, error } = useSWR("productData", fetcher);

  const { hash } = location;

  useEffect(() => {
    if (!data) {
      return;
    }
  }, [data]);

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const destination = document.getElementById(hash.substring(1));
        if (destination) {
          const { offsetTop } = destination;

          window.scrollTo({
            top: offsetTop,
            behavior: "smooth",
          });
        }
      }, 50);
    }
  }, [hash]);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <RevealOnMount location={location}>
      <SEO metaInfo={meta} pagePath={path} slug={slug.current} />
      {categories &&
        categories.length > 0 &&
        categories.map((category) => {
          const collections = category.collections;
          return (
            <div key={category._id} id={category.slug.current}>
              {collections &&
                collections.length > 0 &&
                collections.map((collection: CollectionInterface) => {
                  return (
                    <Collection
                      isCoursePLP={
                        isCoursePLP ||
                        collection.slug.current === "mindfulness" ||
                        collection.slug.current === "mindfulness-courses"
                      }
                      collection={collection}
                      key={collection._id}
                      liveProducts={data}
                    />
                  );
                })}
            </div>
          );
        })}
    </RevealOnMount>
  );
};

export default ProductLandingPage;
